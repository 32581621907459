@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 2s ease-in-out forwards;
}

.fade-in-1 {
  animation-delay: 0s;
}

.fade-in-2 {
  animation-delay: 2s;
}

.fade-in-3 {
  animation-delay: 4s;
}


.custom-upload .ant-upload-list-item-name {
  color: white;
}
