body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
}

.about-text {
    opacity: 0;
    transform: translateX(-50px);
    animation: fadeInLeft 1s forwards;
  }
  
  @keyframes fadeInLeft {
    to {
      opacity: 1.5;
      transform: translateX(0);
    }
  }
  

  .img-fluid {
    opacity: 0;
    transform: translateX(-50px);
    animation: fadeInRight 1s forwards;
  }
  
  @keyframes fadeInRight {
    to {
      opacity: 1.5;
      transform: translateX(0);
    }
  }