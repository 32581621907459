.team-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  width: 90%;
  background-color:  rgb(20 15 35);
}

.team-card:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.image-container {
  width: 150px;
  height: 150px;
  margin: 0 auto 10px;
  overflow: hidden;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.team-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.team-card:hover .image-container {
  transform: scale(1.1);
}

.team-card:hover .team-image {
  transform: scale(1.2);
}

h4 {
  margin: 10px 0 5px;
  font-weight: bold;
  font-size: 1.2rem;
}

p {
  color: #f8f5f5;
  font-size: 1.1rem;
  font-weight: bold;
}
