.mission-vision-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .column {
    flex: 1;
    margin: 10px;
    padding: 20px;
    color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .column h2 {
    margin-bottom: 10px;
  }
  
  .column p {
    font-size: 16px;
    line-height: 1.5;
    color:white;
  }
  